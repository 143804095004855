import { PublicKey } from '@solana/web3.js';
import { u64, MintInfo, MintLayout } from '@solana/spl-token';

// Backward compatibility for new Mint.
type Mint = MintInfo & {
  address?: PublicKey;
};

export const deserializeMint = (data: Buffer, address?: PublicKey) => {
  if (data.length !== MintLayout.span) {
    // This is causing problem with mainnet token
    // throw new Error('Not a valid Mint')
    return;
  }

  const mintInfo = MintLayout.decode(data);
  mintInfo.address = address;

  if (mintInfo.mintAuthorityOption === 0) {
    mintInfo.mintAuthority = null;
  } else {
    mintInfo.mintAuthority = new PublicKey(mintInfo.mintAuthority);
  }

  mintInfo.supply = u64.fromBuffer(mintInfo.supply);
  mintInfo.isInitialized = mintInfo.isInitialized !== 0;

  if (mintInfo.freezeAuthorityOption === 0) {
    mintInfo.freezeAuthority = null;
  } else {
    mintInfo.freezeAuthority = new PublicKey(mintInfo.freezeAuthority);
  }

  return mintInfo as Mint;
};
