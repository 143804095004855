import { TransactionResponse, VersionedTransactionResponse } from '@solana/web3.js';

type CustomInstructionError = [index: number, code: { Custom: number }];
interface ITransactionError {
  InstructionError: CustomInstructionError;
}

export class TransactionError extends Error {
  constructor(m: string, public txid?: string, public code?: number) {
    super(m);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, Error.prototype);
  }
}
export const UNKNOWN_ERROR = 'Unknown error, visit the explorer';
const SYSTEM_PROGRAM_ID = '11111111111111111111111111111111';

export function parseErrorForTransaction(tx: VersionedTransactionResponse | TransactionResponse): {
  message: string;
  programId?: string;
  code?: number;
} {
  // Easy case, logMessages has an obvious error message. From dapp-scaffold
  const errors: string[] = [];
  if (tx?.meta && tx.meta.logMessages) {
    tx.meta.logMessages.forEach((log) => {
      const regex = /Error: (.*)/gm;
      let m;
      while ((m = regex.exec(log)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        if (m.length > 1) {
          errors.push(m[1]);
        }
      }
    });
  }

  if (errors.length > 0) {
    return { message: errors.join(',') };
  }

  // Harder case, we need to dig for a custom code
  const transactionError = tx?.meta?.err;
  let errorCode;

  if (transactionError && typeof transactionError !== 'string') {
    const instructionError = (transactionError as ITransactionError).InstructionError;

    const [index, { Custom }] = instructionError;
    errorCode = Custom ?? (instructionError[1] as any as number);

    if (tx?.meta && tx.meta.logMessages) {
      const failedProgramId = getFailedProgram(tx.meta?.logMessages, errorCode);

      if (failedProgramId) {
        if (failedProgramId === SYSTEM_PROGRAM_ID) {
          return getSystemProgramError(instructionError);
        }

        return {
          message: UNKNOWN_ERROR,
          programId: failedProgramId,
          code: errorCode,
        };
      }
    }
  }

  return { message: typeof transactionError === 'string' ? transactionError : UNKNOWN_ERROR, code: errorCode };
}

function getFailedProgram(logMessages: String[], errorCode: number) {
  for (let i = 0; i < logMessages.length; i++) {
    const log = logMessages[i];

    const found = log.match(
      new RegExp(`Program ([1-9A-HJ-NP-Za-km-z]{32,44}) failed: custom program error: 0x${errorCode.toString(16)}`),
    );

    if (found) {
      return found[1];
    }
  }

  return;
}

function getSystemProgramError(instructionError: CustomInstructionError) {
  const code = instructionError[1].Custom;

  let message = '';
  switch (code) {
    // https://github.com/solana-labs/solana/blob/22a18a68e3ee68ae013d647e62e12128433d7230/sdk/program/src/system_instruction.rs#L12-L26
    // TODO: Do we need to translate all error codes.
    case 0:
      message = 'An account with the same address already exists';
    case 1:
      message = 'The account does not have enough SOL to perform the operation';
    default:
      message = UNKNOWN_ERROR;
  }

  return {
    code,
    programId: SYSTEM_PROGRAM_ID,
    message,
  };
}
