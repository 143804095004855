import { generateErrorMap } from "@saberhq/anchor-contrib";

export type QuarryOperatorIDL = {
  version: "5.3.0";
  name: "quarry_operator";
  instructions: [
    {
      name: "createOperator";
      accounts: [
        {
          name: "base";
          isMut: false;
          isSigner: true;
        },
        {
          name: "operator";
          isMut: true;
          isSigner: false;
          pda: {
            seeds: [
              {
                kind: "const";
                type: "string";
                value: "Operator";
              },
              {
                kind: "account";
                type: "publicKey";
                path: "base";
              }
            ];
          };
        },
        {
          name: "rewarder";
          isMut: true;
          isSigner: false;
        },
        {
          name: "admin";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "quarryMineProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "bump";
          type: "u8";
        }
      ];
    },
    {
      name: "createOperatorV2";
      accounts: [
        {
          name: "base";
          isMut: false;
          isSigner: true;
        },
        {
          name: "operator";
          isMut: true;
          isSigner: false;
          pda: {
            seeds: [
              {
                kind: "const";
                type: "string";
                value: "Operator";
              },
              {
                kind: "account";
                type: "publicKey";
                path: "base";
              }
            ];
          };
        },
        {
          name: "rewarder";
          isMut: true;
          isSigner: false;
        },
        {
          name: "admin";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "quarryMineProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "setAdmin";
      accounts: [
        {
          name: "operator";
          isMut: true;
          isSigner: false;
        },
        {
          name: "admin";
          isMut: false;
          isSigner: true;
        },
        {
          name: "delegate";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "setRateSetter";
      accounts: [
        {
          name: "operator";
          isMut: true;
          isSigner: false;
        },
        {
          name: "admin";
          isMut: false;
          isSigner: true;
        },
        {
          name: "delegate";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "setQuarryCreator";
      accounts: [
        {
          name: "operator";
          isMut: true;
          isSigner: false;
        },
        {
          name: "admin";
          isMut: false;
          isSigner: true;
        },
        {
          name: "delegate";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "setShareAllocator";
      accounts: [
        {
          name: "operator";
          isMut: true;
          isSigner: false;
        },
        {
          name: "admin";
          isMut: false;
          isSigner: true;
        },
        {
          name: "delegate";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "delegateSetAnnualRewards";
      accounts: [
        {
          name: "withDelegate";
          accounts: [
            {
              name: "operator";
              isMut: true;
              isSigner: false;
            },
            {
              name: "delegate";
              isMut: false;
              isSigner: true;
            },
            {
              name: "rewarder";
              isMut: true;
              isSigner: false;
            },
            {
              name: "quarryMineProgram";
              isMut: false;
              isSigner: false;
            }
          ];
        }
      ];
      args: [
        {
          name: "newRate";
          type: "u64";
        }
      ];
    },
    {
      name: "delegateCreateQuarry";
      accounts: [
        {
          name: "withDelegate";
          accounts: [
            {
              name: "operator";
              isMut: true;
              isSigner: false;
            },
            {
              name: "delegate";
              isMut: false;
              isSigner: true;
            },
            {
              name: "rewarder";
              isMut: true;
              isSigner: false;
            },
            {
              name: "quarryMineProgram";
              isMut: false;
              isSigner: false;
            }
          ];
        },
        {
          name: "quarry";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "unusedAccount";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "bump";
          type: "u8";
        }
      ];
    },
    {
      name: "delegateCreateQuarryV2";
      accounts: [
        {
          name: "withDelegate";
          accounts: [
            {
              name: "operator";
              isMut: true;
              isSigner: false;
            },
            {
              name: "delegate";
              isMut: false;
              isSigner: true;
            },
            {
              name: "rewarder";
              isMut: true;
              isSigner: false;
            },
            {
              name: "quarryMineProgram";
              isMut: false;
              isSigner: false;
            }
          ];
        },
        {
          name: "quarry";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "delegateSetRewardsShare";
      accounts: [
        {
          name: "withDelegate";
          accounts: [
            {
              name: "operator";
              isMut: true;
              isSigner: false;
            },
            {
              name: "delegate";
              isMut: false;
              isSigner: true;
            },
            {
              name: "rewarder";
              isMut: true;
              isSigner: false;
            },
            {
              name: "quarryMineProgram";
              isMut: false;
              isSigner: false;
            }
          ];
        },
        {
          name: "quarry";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "newShare";
          type: "u64";
        }
      ];
    },
    {
      name: "delegateSetFamine";
      accounts: [
        {
          name: "withDelegate";
          accounts: [
            {
              name: "operator";
              isMut: true;
              isSigner: false;
            },
            {
              name: "delegate";
              isMut: false;
              isSigner: true;
            },
            {
              name: "rewarder";
              isMut: true;
              isSigner: false;
            },
            {
              name: "quarryMineProgram";
              isMut: false;
              isSigner: false;
            }
          ];
        },
        {
          name: "quarry";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "famineTs";
          type: "i64";
        }
      ];
    }
  ];
  accounts: [
    {
      name: "Operator";
      type: {
        kind: "struct";
        fields: [
          {
            name: "base";
            type: "publicKey";
          },
          {
            name: "bump";
            type: "u8";
          },
          {
            name: "rewarder";
            type: "publicKey";
          },
          {
            name: "admin";
            type: "publicKey";
          },
          {
            name: "rateSetter";
            type: "publicKey";
          },
          {
            name: "quarryCreator";
            type: "publicKey";
          },
          {
            name: "shareAllocator";
            type: "publicKey";
          },
          {
            name: "lastModifiedTs";
            type: "i64";
          },
          {
            name: "generation";
            type: "u64";
          }
        ];
      };
    }
  ];
  errors: [
    {
      code: 6000;
      name: "Unauthorized";
      msg: "Signer is not authorized to perform this action.";
    },
    {
      code: 6001;
      name: "PendingAuthorityNotSet";
      msg: "Pending authority must be set to the created operator.";
    },
    {
      code: 6002;
      name: "OperatorNotRewarderAuthority";
      msg: "Operator is not the Rewarder authority.";
    }
  ];
};
export type AnchorQuarryOperator = {
  version: "5.3.0";
  name: "quarry_operator";
  instructions: [
    {
      name: "createOperator";
      accounts: [
        {
          name: "base";
          isMut: false;
          isSigner: true;
        },
        {
          name: "operator";
          isMut: true;
          isSigner: false;
          pda: {
            seeds: [
              {
                kind: "const";
                type: "string";
                value: "Operator";
              },
              {
                kind: "account";
                type: "publicKey";
                path: "base";
              }
            ];
          };
        },
        {
          name: "rewarder";
          isMut: true;
          isSigner: false;
        },
        {
          name: "admin";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "quarryMineProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "bump";
          type: "u8";
        }
      ];
    },
    {
      name: "createOperatorV2";
      accounts: [
        {
          name: "base";
          isMut: false;
          isSigner: true;
        },
        {
          name: "operator";
          isMut: true;
          isSigner: false;
          pda: {
            seeds: [
              {
                kind: "const";
                type: "string";
                value: "Operator";
              },
              {
                kind: "account";
                type: "publicKey";
                path: "base";
              }
            ];
          };
        },
        {
          name: "rewarder";
          isMut: true;
          isSigner: false;
        },
        {
          name: "admin";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "quarryMineProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "setAdmin";
      accounts: [
        {
          name: "operator";
          isMut: true;
          isSigner: false;
        },
        {
          name: "admin";
          isMut: false;
          isSigner: true;
        },
        {
          name: "delegate";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "setRateSetter";
      accounts: [
        {
          name: "operator";
          isMut: true;
          isSigner: false;
        },
        {
          name: "admin";
          isMut: false;
          isSigner: true;
        },
        {
          name: "delegate";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "setQuarryCreator";
      accounts: [
        {
          name: "operator";
          isMut: true;
          isSigner: false;
        },
        {
          name: "admin";
          isMut: false;
          isSigner: true;
        },
        {
          name: "delegate";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "setShareAllocator";
      accounts: [
        {
          name: "operator";
          isMut: true;
          isSigner: false;
        },
        {
          name: "admin";
          isMut: false;
          isSigner: true;
        },
        {
          name: "delegate";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "delegateSetAnnualRewards";
      accounts: [
        {
          name: "withDelegate";
          accounts: [
            {
              name: "operator";
              isMut: true;
              isSigner: false;
            },
            {
              name: "delegate";
              isMut: false;
              isSigner: true;
            },
            {
              name: "rewarder";
              isMut: true;
              isSigner: false;
            },
            {
              name: "quarryMineProgram";
              isMut: false;
              isSigner: false;
            }
          ];
        }
      ];
      args: [
        {
          name: "newRate";
          type: "u64";
        }
      ];
    },
    {
      name: "delegateCreateQuarry";
      accounts: [
        {
          name: "withDelegate";
          accounts: [
            {
              name: "operator";
              isMut: true;
              isSigner: false;
            },
            {
              name: "delegate";
              isMut: false;
              isSigner: true;
            },
            {
              name: "rewarder";
              isMut: true;
              isSigner: false;
            },
            {
              name: "quarryMineProgram";
              isMut: false;
              isSigner: false;
            }
          ];
        },
        {
          name: "quarry";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "unusedAccount";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "bump";
          type: "u8";
        }
      ];
    },
    {
      name: "delegateCreateQuarryV2";
      accounts: [
        {
          name: "withDelegate";
          accounts: [
            {
              name: "operator";
              isMut: true;
              isSigner: false;
            },
            {
              name: "delegate";
              isMut: false;
              isSigner: true;
            },
            {
              name: "rewarder";
              isMut: true;
              isSigner: false;
            },
            {
              name: "quarryMineProgram";
              isMut: false;
              isSigner: false;
            }
          ];
        },
        {
          name: "quarry";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "delegateSetRewardsShare";
      accounts: [
        {
          name: "withDelegate";
          accounts: [
            {
              name: "operator";
              isMut: true;
              isSigner: false;
            },
            {
              name: "delegate";
              isMut: false;
              isSigner: true;
            },
            {
              name: "rewarder";
              isMut: true;
              isSigner: false;
            },
            {
              name: "quarryMineProgram";
              isMut: false;
              isSigner: false;
            }
          ];
        },
        {
          name: "quarry";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "newShare";
          type: "u64";
        }
      ];
    },
    {
      name: "delegateSetFamine";
      accounts: [
        {
          name: "withDelegate";
          accounts: [
            {
              name: "operator";
              isMut: true;
              isSigner: false;
            },
            {
              name: "delegate";
              isMut: false;
              isSigner: true;
            },
            {
              name: "rewarder";
              isMut: true;
              isSigner: false;
            },
            {
              name: "quarryMineProgram";
              isMut: false;
              isSigner: false;
            }
          ];
        },
        {
          name: "quarry";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "famineTs";
          type: "i64";
        }
      ];
    }
  ];
  accounts: [
    {
      name: "operator";
      type: {
        kind: "struct";
        fields: [
          {
            name: "base";
            type: "publicKey";
          },
          {
            name: "bump";
            type: "u8";
          },
          {
            name: "rewarder";
            type: "publicKey";
          },
          {
            name: "admin";
            type: "publicKey";
          },
          {
            name: "rateSetter";
            type: "publicKey";
          },
          {
            name: "quarryCreator";
            type: "publicKey";
          },
          {
            name: "shareAllocator";
            type: "publicKey";
          },
          {
            name: "lastModifiedTs";
            type: "i64";
          },
          {
            name: "generation";
            type: "u64";
          }
        ];
      };
    }
  ];
  errors: [
    {
      code: 6000;
      name: "Unauthorized";
      msg: "Signer is not authorized to perform this action.";
    },
    {
      code: 6001;
      name: "PendingAuthorityNotSet";
      msg: "Pending authority must be set to the created operator.";
    },
    {
      code: 6002;
      name: "OperatorNotRewarderAuthority";
      msg: "Operator is not the Rewarder authority.";
    }
  ];
};

export const AnchorQuarryOperatorIDL: AnchorQuarryOperator = {
  version: "5.3.0",
  name: "quarry_operator",
  instructions: [
    {
      name: "createOperator",
      accounts: [
        {
          name: "base",
          isMut: false,
          isSigner: true,
        },
        {
          name: "operator",
          isMut: true,
          isSigner: false,
          pda: {
            seeds: [
              {
                kind: "const",
                type: "string",
                value: "Operator",
              },
              {
                kind: "account",
                type: "publicKey",
                path: "base",
              },
            ],
          },
        },
        {
          name: "rewarder",
          isMut: true,
          isSigner: false,
        },
        {
          name: "admin",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "quarryMineProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "bump",
          type: "u8",
        },
      ],
    },
    {
      name: "createOperatorV2",
      accounts: [
        {
          name: "base",
          isMut: false,
          isSigner: true,
        },
        {
          name: "operator",
          isMut: true,
          isSigner: false,
          pda: {
            seeds: [
              {
                kind: "const",
                type: "string",
                value: "Operator",
              },
              {
                kind: "account",
                type: "publicKey",
                path: "base",
              },
            ],
          },
        },
        {
          name: "rewarder",
          isMut: true,
          isSigner: false,
        },
        {
          name: "admin",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "quarryMineProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "setAdmin",
      accounts: [
        {
          name: "operator",
          isMut: true,
          isSigner: false,
        },
        {
          name: "admin",
          isMut: false,
          isSigner: true,
        },
        {
          name: "delegate",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "setRateSetter",
      accounts: [
        {
          name: "operator",
          isMut: true,
          isSigner: false,
        },
        {
          name: "admin",
          isMut: false,
          isSigner: true,
        },
        {
          name: "delegate",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "setQuarryCreator",
      accounts: [
        {
          name: "operator",
          isMut: true,
          isSigner: false,
        },
        {
          name: "admin",
          isMut: false,
          isSigner: true,
        },
        {
          name: "delegate",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "setShareAllocator",
      accounts: [
        {
          name: "operator",
          isMut: true,
          isSigner: false,
        },
        {
          name: "admin",
          isMut: false,
          isSigner: true,
        },
        {
          name: "delegate",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "delegateSetAnnualRewards",
      accounts: [
        {
          name: "withDelegate",
          accounts: [
            {
              name: "operator",
              isMut: true,
              isSigner: false,
            },
            {
              name: "delegate",
              isMut: false,
              isSigner: true,
            },
            {
              name: "rewarder",
              isMut: true,
              isSigner: false,
            },
            {
              name: "quarryMineProgram",
              isMut: false,
              isSigner: false,
            },
          ],
        },
      ],
      args: [
        {
          name: "newRate",
          type: "u64",
        },
      ],
    },
    {
      name: "delegateCreateQuarry",
      accounts: [
        {
          name: "withDelegate",
          accounts: [
            {
              name: "operator",
              isMut: true,
              isSigner: false,
            },
            {
              name: "delegate",
              isMut: false,
              isSigner: true,
            },
            {
              name: "rewarder",
              isMut: true,
              isSigner: false,
            },
            {
              name: "quarryMineProgram",
              isMut: false,
              isSigner: false,
            },
          ],
        },
        {
          name: "quarry",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "unusedAccount",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "bump",
          type: "u8",
        },
      ],
    },
    {
      name: "delegateCreateQuarryV2",
      accounts: [
        {
          name: "withDelegate",
          accounts: [
            {
              name: "operator",
              isMut: true,
              isSigner: false,
            },
            {
              name: "delegate",
              isMut: false,
              isSigner: true,
            },
            {
              name: "rewarder",
              isMut: true,
              isSigner: false,
            },
            {
              name: "quarryMineProgram",
              isMut: false,
              isSigner: false,
            },
          ],
        },
        {
          name: "quarry",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "delegateSetRewardsShare",
      accounts: [
        {
          name: "withDelegate",
          accounts: [
            {
              name: "operator",
              isMut: true,
              isSigner: false,
            },
            {
              name: "delegate",
              isMut: false,
              isSigner: true,
            },
            {
              name: "rewarder",
              isMut: true,
              isSigner: false,
            },
            {
              name: "quarryMineProgram",
              isMut: false,
              isSigner: false,
            },
          ],
        },
        {
          name: "quarry",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "newShare",
          type: "u64",
        },
      ],
    },
    {
      name: "delegateSetFamine",
      accounts: [
        {
          name: "withDelegate",
          accounts: [
            {
              name: "operator",
              isMut: true,
              isSigner: false,
            },
            {
              name: "delegate",
              isMut: false,
              isSigner: true,
            },
            {
              name: "rewarder",
              isMut: true,
              isSigner: false,
            },
            {
              name: "quarryMineProgram",
              isMut: false,
              isSigner: false,
            },
          ],
        },
        {
          name: "quarry",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "famineTs",
          type: "i64",
        },
      ],
    },
  ],
  accounts: [
    {
      name: "operator",
      type: {
        kind: "struct",
        fields: [
          {
            name: "base",
            type: "publicKey",
          },
          {
            name: "bump",
            type: "u8",
          },
          {
            name: "rewarder",
            type: "publicKey",
          },
          {
            name: "admin",
            type: "publicKey",
          },
          {
            name: "rateSetter",
            type: "publicKey",
          },
          {
            name: "quarryCreator",
            type: "publicKey",
          },
          {
            name: "shareAllocator",
            type: "publicKey",
          },
          {
            name: "lastModifiedTs",
            type: "i64",
          },
          {
            name: "generation",
            type: "u64",
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 6000,
      name: "Unauthorized",
      msg: "Signer is not authorized to perform this action.",
    },
    {
      code: 6001,
      name: "PendingAuthorityNotSet",
      msg: "Pending authority must be set to the created operator.",
    },
    {
      code: 6002,
      name: "OperatorNotRewarderAuthority",
      msg: "Operator is not the Rewarder authority.",
    },
  ],
};
export const QuarryOperatorJSON: QuarryOperatorIDL = {
  version: "5.3.0",
  name: "quarry_operator",
  instructions: [
    {
      name: "createOperator",
      accounts: [
        {
          name: "base",
          isMut: false,
          isSigner: true,
        },
        {
          name: "operator",
          isMut: true,
          isSigner: false,
          pda: {
            seeds: [
              {
                kind: "const",
                type: "string",
                value: "Operator",
              },
              {
                kind: "account",
                type: "publicKey",
                path: "base",
              },
            ],
          },
        },
        {
          name: "rewarder",
          isMut: true,
          isSigner: false,
        },
        {
          name: "admin",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "quarryMineProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "bump",
          type: "u8",
        },
      ],
    },
    {
      name: "createOperatorV2",
      accounts: [
        {
          name: "base",
          isMut: false,
          isSigner: true,
        },
        {
          name: "operator",
          isMut: true,
          isSigner: false,
          pda: {
            seeds: [
              {
                kind: "const",
                type: "string",
                value: "Operator",
              },
              {
                kind: "account",
                type: "publicKey",
                path: "base",
              },
            ],
          },
        },
        {
          name: "rewarder",
          isMut: true,
          isSigner: false,
        },
        {
          name: "admin",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "quarryMineProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "setAdmin",
      accounts: [
        {
          name: "operator",
          isMut: true,
          isSigner: false,
        },
        {
          name: "admin",
          isMut: false,
          isSigner: true,
        },
        {
          name: "delegate",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "setRateSetter",
      accounts: [
        {
          name: "operator",
          isMut: true,
          isSigner: false,
        },
        {
          name: "admin",
          isMut: false,
          isSigner: true,
        },
        {
          name: "delegate",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "setQuarryCreator",
      accounts: [
        {
          name: "operator",
          isMut: true,
          isSigner: false,
        },
        {
          name: "admin",
          isMut: false,
          isSigner: true,
        },
        {
          name: "delegate",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "setShareAllocator",
      accounts: [
        {
          name: "operator",
          isMut: true,
          isSigner: false,
        },
        {
          name: "admin",
          isMut: false,
          isSigner: true,
        },
        {
          name: "delegate",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "delegateSetAnnualRewards",
      accounts: [
        {
          name: "withDelegate",
          accounts: [
            {
              name: "operator",
              isMut: true,
              isSigner: false,
            },
            {
              name: "delegate",
              isMut: false,
              isSigner: true,
            },
            {
              name: "rewarder",
              isMut: true,
              isSigner: false,
            },
            {
              name: "quarryMineProgram",
              isMut: false,
              isSigner: false,
            },
          ],
        },
      ],
      args: [
        {
          name: "newRate",
          type: "u64",
        },
      ],
    },
    {
      name: "delegateCreateQuarry",
      accounts: [
        {
          name: "withDelegate",
          accounts: [
            {
              name: "operator",
              isMut: true,
              isSigner: false,
            },
            {
              name: "delegate",
              isMut: false,
              isSigner: true,
            },
            {
              name: "rewarder",
              isMut: true,
              isSigner: false,
            },
            {
              name: "quarryMineProgram",
              isMut: false,
              isSigner: false,
            },
          ],
        },
        {
          name: "quarry",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "unusedAccount",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "bump",
          type: "u8",
        },
      ],
    },
    {
      name: "delegateCreateQuarryV2",
      accounts: [
        {
          name: "withDelegate",
          accounts: [
            {
              name: "operator",
              isMut: true,
              isSigner: false,
            },
            {
              name: "delegate",
              isMut: false,
              isSigner: true,
            },
            {
              name: "rewarder",
              isMut: true,
              isSigner: false,
            },
            {
              name: "quarryMineProgram",
              isMut: false,
              isSigner: false,
            },
          ],
        },
        {
          name: "quarry",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "delegateSetRewardsShare",
      accounts: [
        {
          name: "withDelegate",
          accounts: [
            {
              name: "operator",
              isMut: true,
              isSigner: false,
            },
            {
              name: "delegate",
              isMut: false,
              isSigner: true,
            },
            {
              name: "rewarder",
              isMut: true,
              isSigner: false,
            },
            {
              name: "quarryMineProgram",
              isMut: false,
              isSigner: false,
            },
          ],
        },
        {
          name: "quarry",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "newShare",
          type: "u64",
        },
      ],
    },
    {
      name: "delegateSetFamine",
      accounts: [
        {
          name: "withDelegate",
          accounts: [
            {
              name: "operator",
              isMut: true,
              isSigner: false,
            },
            {
              name: "delegate",
              isMut: false,
              isSigner: true,
            },
            {
              name: "rewarder",
              isMut: true,
              isSigner: false,
            },
            {
              name: "quarryMineProgram",
              isMut: false,
              isSigner: false,
            },
          ],
        },
        {
          name: "quarry",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "famineTs",
          type: "i64",
        },
      ],
    },
  ],
  accounts: [
    {
      name: "Operator",
      type: {
        kind: "struct",
        fields: [
          {
            name: "base",
            type: "publicKey",
          },
          {
            name: "bump",
            type: "u8",
          },
          {
            name: "rewarder",
            type: "publicKey",
          },
          {
            name: "admin",
            type: "publicKey",
          },
          {
            name: "rateSetter",
            type: "publicKey",
          },
          {
            name: "quarryCreator",
            type: "publicKey",
          },
          {
            name: "shareAllocator",
            type: "publicKey",
          },
          {
            name: "lastModifiedTs",
            type: "i64",
          },
          {
            name: "generation",
            type: "u64",
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 6000,
      name: "Unauthorized",
      msg: "Signer is not authorized to perform this action.",
    },
    {
      code: 6001,
      name: "PendingAuthorityNotSet",
      msg: "Pending authority must be set to the created operator.",
    },
    {
      code: 6002,
      name: "OperatorNotRewarderAuthority",
      msg: "Operator is not the Rewarder authority.",
    },
  ],
};
export const QuarryOperatorErrors = generateErrorMap(QuarryOperatorJSON);
