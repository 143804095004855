import React, { useEffect, useState, useMemo, useCallback, useRef, createContext, useContext } from 'react';
import { Connection, PublicKey, Transaction, VersionedTransaction } from '@solana/web3.js';
import { Errors } from './error';
import useDebounce from './utils/useDebounce';
import type { Jupiter, SwapResult, JupiterLoadParams } from '@jup-ag/core';
import {
  SwapMode,
  INDEXED_ROUTE_MAP_URL,
  getRemoteRouteMap,
  TransactionError,
  executeTransactions,
  Owner,
} from '@jup-ag/core';
import JSBI from 'jsbi';
import { Configuration, DefaultApi, V4QuoteGetSwapModeEnum } from '@jup-ag/api';
import fetch from 'cross-fetch';
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { parseAPISerializedRouteInfoToRouteInfo, RouteInfo, serializeRouteInfo } from './utils/parseRouteInfos';

export type JupiterError = (typeof Errors)[keyof typeof Errors];

interface UseJupiterResult {
  /** routes that are possible, sorted decending based on outAmount */
  routes?: RouteInfo[];
  /** exchange function to submit transaction */
  exchange: (
    params: Omit<Parameters<Jupiter['exchange']>[0], 'routeInfo'> & { routeInfo: RouteInfo } & Parameters<
        Awaited<ReturnType<Jupiter['exchange']>>['execute']
      >[0],
  ) => Promise<SwapResult>;
  /** refresh function to refetch the prices */
  refresh: () => void;
  /** last refresh timestamp */
  lastRefreshTimestamp: number;
  /** all possible token mints to be chosen from */
  allTokenMints: string[];
  /** route map input mint with output mints */
  routeMap: Map<string, string[]>;
  /** loading state */
  loading: boolean;
  error: JupiterError | undefined;
}

interface JupiterProps extends Pick<JupiterLoadParams, 'connection' | 'platformFeeAndAccounts' | 'wrapUnwrapSOL'> {
  onlyDirectRoutes?: boolean;
  asLegacyTransaction?: boolean;
  userPublicKey?: PublicKey;
  routeCacheDuration?: number;
  jupiterQuoteApiUrl?: string;
  children?: React.ReactNode;
}

const JupiterContext = createContext<
  | (Pick<UseJupiterResult, 'allTokenMints' | 'routeMap'> &
      JupiterProps & {
        connection: Connection;
        jupiterApiClient: DefaultApi;
        error: JupiterError | undefined;
        setError: (error?: JupiterError) => void;
      })
  | null
>(null);

export const JupiterProvider: React.FC<JupiterProps> = ({
  onlyDirectRoutes,
  userPublicKey,
  jupiterQuoteApiUrl,
  children,
  ...jupiterLoadProps
}) => {
  const [routeMap, setRouteMap] = useState(new Map<string, string[]>());
  const [error, setError] = useState<JupiterError>();

  const jupiterApiClient = useMemo(() => {
    const configuration = new Configuration({
      basePath: jupiterQuoteApiUrl ?? 'https://quote-api.jup.ag',
      fetchApi: fetch,
    });
    return new DefaultApi(configuration);
  }, [jupiterQuoteApiUrl]);

  useEffect(() => {
    async function update() {
      // so that we follow the marketUrl host name and get preprod and prod
      const url = new URL(INDEXED_ROUTE_MAP_URL).toString();

      const routeMap = await getRemoteRouteMap(
        {
          onlyDirectRoutes,
          restrictIntermediateTokens: true,
          asLegacyTransaction: jupiterLoadProps.asLegacyTransaction,
        },
        url,
      );

      setRouteMap(routeMap);
    }
    update();
  }, [onlyDirectRoutes, jupiterLoadProps.asLegacyTransaction]);

  const allTokenMints = useMemo(() => {
    return Array.from(routeMap.keys());
  }, [routeMap]);

  return (
    <JupiterContext.Provider
      value={{
        ...jupiterLoadProps,
        userPublicKey,
        jupiterApiClient,
        allTokenMints,
        routeMap,
        error,
        setError,
        onlyDirectRoutes,
      }}
    >
      {children}
    </JupiterContext.Provider>
  );
};

interface UseJupiterProps {
  amount: JSBI;
  inputMint: PublicKey | undefined;
  outputMint: PublicKey | undefined;
  slippageBps: number;
  /* inputAmount is being debounced, debounceTime 0 to disable debounce */
  debounceTime?: number;
  swapMode?: SwapMode;
  asLegacyTransaction?: boolean;
}

export const useJupiterRouteMap = () => {
  const context = useContext(JupiterContext);
  if (!context) {
    throw new Error('JupiterProvider is required');
  }
  return context.routeMap;
};

export const useJupiter = ({
  amount,
  inputMint,
  outputMint,
  slippageBps,
  swapMode,
  debounceTime = 250,
}: UseJupiterProps): UseJupiterResult => {
  const context = useContext(JupiterContext);
  const [loading, setLoading] = useState(false);
  const [routes, setRoutes] = useState<RouteInfo[]>();
  const [refreshCount, setRefreshCount] = useState<number>(0);
  // lastRefreshCount indicate when the last refresh was triggered on which refreshCount
  const lastRefreshCount = useRef<number>(refreshCount);

  const [debouncedAmount, debouncedInputMint, debouncedOutputMint] = useDebounce(
    React.useMemo(() => {
      // called immediaetly instead of waiting debounce because we want to show the loading state
      if (JSBI.greaterThan(amount, JSBI.BigInt(0))) {
        setLoading(true);
      }
      return [amount, inputMint, outputMint];
    }, [amount.toString(), inputMint?.toBase58(), outputMint?.toBase58()]),
    debounceTime,
  );

  const lastRefreshTimestamp = useRef<number>(0);
  const lastQueryTimestamp = useRef<number>(0);

  if (!context) {
    throw new Error('JupiterProvider is required');
  }

  const {
    routeMap,
    allTokenMints,
    error,
    setError,
    routeCacheDuration = 0,
    onlyDirectRoutes,
    jupiterApiClient,
    userPublicKey,
    connection,
    wrapUnwrapSOL,
    asLegacyTransaction,
    platformFeeAndAccounts,
  } = context;

  // lastRefreshCount to determine when the last refresh was triggered, reset this to -1 to trigger a re-fetch
  useEffect(() => {
    lastRefreshCount.current = -1;
  }, [[debouncedInputMint?.toString(), debouncedOutputMint?.toString()].sort().join('-'), slippageBps]);

  useEffect(() => {
    // if now - lastRefreshTimestamp > routeCacheDuration, then we need to refresh
    if (lastRefreshTimestamp.current && new Date().getTime() - lastRefreshTimestamp.current >= routeCacheDuration) {
      lastRefreshCount.current = -1;
    }

    // don't set loading if there is no input amount
    if (JSBI.greaterThan(debouncedAmount, JSBI.BigInt(0))) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    refreshCount,
    debouncedAmount,
    slippageBps,
    debouncedInputMint,
    debouncedOutputMint,
    onlyDirectRoutes,
    asLegacyTransaction,
  ]);

  useEffect(() => {
    async function fetch() {
      if (JSBI.equal(debouncedAmount, JSBI.BigInt(0)) || error === Errors.INITIALIZE_ERROR) {
        setLoading(false);
        setRoutes(undefined);
      } else if (debouncedAmount) {
        if (!debouncedInputMint || !debouncedOutputMint || !routeMap) return;
        const lastUpdatedTime = new Date().getTime();
        lastQueryTimestamp.current = lastUpdatedTime;

        try {
          const inputMint = debouncedInputMint.toBase58();
          const outputMint = debouncedOutputMint.toBase58();
          const platformFeeMint = swapMode === SwapMode.ExactOut ? inputMint : outputMint;
          const response = await jupiterApiClient.v4QuoteGet({
            amount: debouncedAmount.toString(),
            inputMint,
            outputMint,
            userPublicKey: userPublicKey?.toBase58(),
            slippageBps,
            swapMode: swapMode ? V4QuoteGetSwapModeEnum[swapMode] : undefined,
            onlyDirectRoutes: onlyDirectRoutes,
            asLegacyTransaction,
            feeBps: platformFeeAndAccounts?.feeAccounts.get(platformFeeMint)
              ? platformFeeAndAccounts?.feeBps
              : undefined,
          });

          if (lastQueryTimestamp.current !== lastUpdatedTime) {
            return;
          }

          if (response.data) {
            const parsedRoutes = parseAPISerializedRouteInfoToRouteInfo(response.data);
            setRoutes(parsedRoutes);

            setError(undefined);
            lastRefreshTimestamp.current = new Date().getTime();
          }
        } catch (e) {
          console.error(e);
          if (lastQueryTimestamp.current !== lastUpdatedTime) {
            return;
          }
          // Clear routes when erring to avoid bad pricing
          setRoutes(undefined);
          setError(Errors.ROUTES_ERROR);
        } finally {
          if (lastQueryTimestamp.current !== lastUpdatedTime) {
            return;
          }
          lastRefreshCount.current = refreshCount;
          setLoading(false);
        }
      }
    }

    fetch();
  }, [
    platformFeeAndAccounts,
    debouncedAmount,
    debouncedInputMint,
    debouncedOutputMint,
    slippageBps,
    swapMode,
    userPublicKey,
    refreshCount,
    onlyDirectRoutes,
    asLegacyTransaction,
  ]);

  const exchange: UseJupiterResult['exchange'] = useCallback(
    async ({
      wallet,
      routeInfo,
      onTransaction,
      computeUnitPriceMicroLamports,
      ...restExchangeProps
    }): Promise<SwapResult> => {
      if (!userPublicKey) throw new Error('User public key is required');
      if (!wallet) throw new Error('Wallet is required');

      const [inputMint, outputMint] = [
        routeInfo.marketInfos[0].inputMint,
        routeInfo.marketInfos[routeInfo.marketInfos.length - 1].outputMint,
      ];

      const useWrappedSOL = restExchangeProps.wrapUnwrapSOL ?? wrapUnwrapSOL ?? true;
      const platformFeeMint = swapMode === SwapMode.ExactOut ? inputMint : outputMint;

      const result = await jupiterApiClient
        .v4SwapPost({
          body: {
            route: serializeRouteInfo(routeInfo as any) as any,
            userPublicKey: userPublicKey.toBase58(),
            wrapUnwrapSOL: useWrappedSOL,
            computeUnitPriceMicroLamports: computeUnitPriceMicroLamports || undefined,
            asLegacyTransaction,
            feeAccount: platformFeeAndAccounts?.feeAccounts.get(platformFeeMint.toBase58())?.toBase58(),
          },
        })
        .catch(async (res) => {
          const { error, message } = await res.json();
          return {
            error: new TransactionError(message, undefined, error),
          };
        });

      if ('error' in result) {
        return result;
      }
      const { swapTransaction: swapTransactionSerialized } = result;

      const swapTransactionBuf = Buffer.from(swapTransactionSerialized!, 'base64');

      let swapTransaction = asLegacyTransaction
        ? Transaction.from(swapTransactionBuf)
        : VersionedTransaction.deserialize(swapTransactionBuf);

      const [sourceAddress, destinationAddress] = await Promise.all(
        [inputMint, outputMint].map((mint) =>
          Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, mint, userPublicKey, true),
        ),
      );

      return executeTransactions({
        connection,
        wallet,
        onTransaction,
        inputMint,
        outputMint,
        sourceAddress,
        destinationAddress,
        swapTransaction,
        wrapUnwrapSOL: useWrappedSOL,
        owner: new Owner(userPublicKey),
      });
    },
    [userPublicKey, wrapUnwrapSOL, asLegacyTransaction, connection, platformFeeAndAccounts],
  );

  return {
    allTokenMints,
    routeMap,
    exchange,
    refresh: () => {
      if (!loading && lastRefreshTimestamp.current) {
        setRefreshCount((refreshCount) => refreshCount + 1);
      }
    },
    lastRefreshTimestamp: lastRefreshTimestamp.current,
    loading,
    routes,
    error,
  };
};

export type { RouteInfo };
export { Errors };
export {
  Owner,
  SwapMode,
  TransactionBuilder,
  JUPITER_ERRORS,
  LAMPORTS_PER_SIGNATURE,
  MARKETS_URL,
  TOKEN_LIST_URL,
  getPlatformFeeAccounts,
  JUPITER_FEE_OWNER,
} from '@jup-ag/core';

export type {
  ErrorDetails,
  Fee,
  IConfirmationTxDescription,
  IndexedRouteMap,
  Instruction,
  TransactionFeeInfo,
  OnTransaction,
  SwapResult,
} from '@jup-ag/core';
