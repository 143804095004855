import { InlineResponse200Data } from '@jup-ag/api';
import { SwapMode, TransactionFeeInfo } from '@jup-ag/core';
import { PublicKey } from '@solana/web3.js';
import JSBI from 'jsbi';

export interface RouteInfo<Pubkey = PublicKey, numberOrJSBI = JSBI> {
  marketInfos: {
    id: string;
    label: string;
    inputMint: Pubkey;
    outputMint: Pubkey;
    inAmount: numberOrJSBI;
    outAmount: numberOrJSBI;
    minInAmount?: numberOrJSBI;
    minOutAmount?: numberOrJSBI;
    notEnoughLiquidity: boolean;
    priceImpactPct: number;
    lpFee: {
      amount: numberOrJSBI;
      mint: string;
      pct: number;
    };
    platformFee: { amount: numberOrJSBI; mint: string; pct: number };
  }[];
  inAmount: numberOrJSBI;
  outAmount: numberOrJSBI;
  amount: numberOrJSBI;
  otherAmountThreshold: numberOrJSBI;
  slippageBps: number;
  swapMode: SwapMode;
  priceImpactPct: number;
  fees?: TransactionFeeInfo;
}

export const parseAPISerializedRouteInfoToRouteInfo = (serializedRouteInfos: InlineResponse200Data[]): RouteInfo[] => {
  return serializedRouteInfos.map((serializedRouteInfo) => ({
    marketInfos: serializedRouteInfo.marketInfos.map(
      ({
        id,
        inAmount,
        inputMint,
        label,
        lpFee,
        notEnoughLiquidity,
        outAmount,
        outputMint,
        platformFee,
        priceImpactPct,
        minInAmount,
        minOutAmount,
      }) => ({
        id,
        label,
        notEnoughLiquidity,
        priceImpactPct,
        inputMint: new PublicKey(inputMint!),
        outputMint: new PublicKey(outputMint!),
        lpFee: {
          mint: lpFee!.mint,
          pct: lpFee!.pct,
          amount: JSBI.BigInt(lpFee!.amount!),
        },
        platformFee: {
          mint: platformFee!.mint!,
          pct: platformFee!.pct!,
          amount: JSBI.BigInt(platformFee!.amount!),
        },
        inAmount: JSBI.BigInt(inAmount!),
        outAmount: JSBI.BigInt(outAmount!),
        minInAmount: minInAmount ? JSBI.BigInt(minInAmount) : undefined,
        minOutAmount: minOutAmount ? JSBI.BigInt(minOutAmount) : undefined,
      }),
    ),
    fees: serializedRouteInfo.fees,
    priceImpactPct: serializedRouteInfo.priceImpactPct,
    slippageBps: serializedRouteInfo.slippageBps,
    swapMode: serializedRouteInfo.swapMode as any as SwapMode,
    inAmount: JSBI.BigInt(serializedRouteInfo.inAmount),
    outAmount: JSBI.BigInt(serializedRouteInfo.outAmount),
    amount: JSBI.BigInt(serializedRouteInfo.amount),
    otherAmountThreshold: JSBI.BigInt(serializedRouteInfo.otherAmountThreshold),
  })) as RouteInfo[];
};

export const serializeRouteInfo = (routeInfo: RouteInfo<PublicKey, JSBI>): RouteInfo<string, string> => {
  return {
    ...routeInfo,
    marketInfos: routeInfo.marketInfos.map((marketInfo) => ({
      ...marketInfo,
      inputMint: marketInfo.inputMint.toBase58(),
      outputMint: marketInfo.outputMint.toBase58(),
      lpFee: {
        ...marketInfo.lpFee,
        amount: marketInfo.lpFee.amount.toString(),
      },
      platformFee: {
        ...marketInfo.platformFee,
        amount: marketInfo.platformFee.amount.toString(),
      },
      inAmount: marketInfo.inAmount.toString(),
      outAmount: marketInfo.outAmount.toString(),
      minInAmount: marketInfo.minInAmount ? marketInfo.minInAmount.toString() : undefined,
      minOutAmount: marketInfo.minOutAmount ? marketInfo.minOutAmount.toString() : undefined,
    })),
    inAmount: routeInfo.inAmount.toString(),
    outAmount: routeInfo.outAmount.toString(),
    amount: routeInfo.amount.toString(),
    otherAmountThreshold: routeInfo.otherAmountThreshold.toString(),
  };
};
